$retrospective-blue: #025d8c;

$custom-colors: (
	'retrospective-blue': (
		$retrospective-blue,
		findColorInvert($retrospective-blue),
	),
);

$primary: $retrospective-blue;

@import '~bulma/bulma';

.retro-item {
	transition: transform 200ms ease-out;
}

.retro-item.focused {
	box-shadow: 0 0 15px 0px $primary;
	transform: scale(1.05);
	border: 2px solid $primary;
}
